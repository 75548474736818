/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { subYears } from "date-fns";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import { FixedHeightScrollLastChild } from "src/components/StyledComponents";

import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import OrderApprovalByItemTable from "../components/OrderManagement/OrderApprovalByItemTable";
import OrderApprovalTable from "../components/OrderManagement/OrderApprovalTable";
import {
  orderApprovalItem,
  orderApprovalOrder,
} from "../components/OrderManagement/inViewReporting";
import ConfirmDenyModal from "../components/Utility/Modals/ConfirmDenyModal";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import { setSorted, updateMultipleFilters } from "../redux/slices/filterSlice";
import {
  fetchNextFilteredOrderSetVariants,
  fetchNextFilteredOrderSets,
  fetchOrderSetReport,
  fetchOrderSetVariantReport,
} from "../redux/slices/ordering/orderSetHistorySlice";
import {
  approveMultipleOrderSets,
  denyOrdSet,
} from "../redux/slices/patchOrderSlice";

/*
Gallo utilizes an approval flow for ordering, and this page allows field 2 and up users to view,
approve, or deny field 1 users order sets.
*/

const defaultFilters = {
  fromDate: format(subYears(new Date(), 1), "MM/dd/yyyy"),
  toDate: format(addDays(new Date(), 1), "MM/dd/yyyy"),
  user: [],
  address: [],
  program: [],
  brand: [],
  itemType: [],
  territory: [],
  itemNumber: "",
  orderNum: "",
  groupBy: "item",
  type: "not-pre-order",
  status: "submitted,partial-approval-error",
  sortOrder: "asc",
  sortOrderBy: "orderDate",
  preventFetch: false,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OrderApprovals = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [isDenyOpen, setDenyOpen] = useState(false);
  const [denyId, setDenyId] = useState(null);

  const {
    nextLink,
    isNextLoading,
    isLoading: isOrdersLoading,
    orderSets: currentOrders,
    itemGroups: currentItems,
    triggerCSVDownload,
    orderSetReport,
    orderSetItemReport,
  } = useSelector((state) => state.orderSetHistory);

  const allFilters = useSelector((state) => state.filters);
  const { role: currentUserRole, currentChannel } = useSelector(
    (state) => state.user
  );
  const currentGrouping = useSelector((state) => state.filters.groupBy);
  const filterObject = useSelector((state) => state.filters);
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);

  const showBudgets = filterObject.preventFetch;

  defaultFilters.channel = currentChannel;

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  const handleOrderSetReport = () => {
    if (currentGrouping === "order") {
      dispatch(fetchOrderSetReport(filterObject));
    } else {
      dispatch(fetchOrderSetVariantReport(filterObject));
    }
  };

  const handleDenyModal = (id) => {
    setDenyOpen(true);
    setDenyId(id);
  };

  const handleDenyModalClose = () => {
    setDenyOpen(false);
    setDenyId(null);
  };

  const handleDeny = (id, note) => {
    dispatch(denyOrdSet(id, allFilters, note));
    handleDenyModalClose();
  };

  const handleBulkApproval = () => {
    dispatch(approveMultipleOrderSets(selected, allFilters));
    setSelected([]);
  };

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        if (currentGrouping === "order") {
          dispatch(fetchNextFilteredOrderSets(nextLink));
        } else {
          dispatch(fetchNextFilteredOrderSetVariants(nextLink));
        }
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const csv =
    currentGrouping === "order"
      ? {
          data: orderApprovalOrder.mapFunction(orderSetReport),
          headers: orderApprovalOrder.headers,
        }
      : {
          data: orderApprovalItem.mapFunction(orderSetItemReport),
          headers: orderApprovalItem.headers,
        };

  useChannelUpdate(currentChannel, filterObject.channel, dispatch);

  useInitialFilters("history-approvals", defaultFilters);

  return (
    <>
      <Helmet>
        <title>RTA | Order Approval</title>
        {currentUserRole === "field2" && !filtersOpen && (
          <script type="text/javascript">{` Beacon('suggest', ['600af2ff1c64ad47e4b7201d'])`}</script>
        )}
      </Helmet>
      {isDenyOpen && denyId && (
        <ConfirmDenyModal
          open={isDenyOpen}
          handleClose={handleDenyModalClose}
          handleDeny={handleDeny}
          orderSetId={denyId}
        />
      )}
      <Container className={classes.mainWrapper}>
        <FixedHeightScrollLastChild tw="[--space-after-header: 42px]">
          <div className={classes.titleBar}>
            <Typography className={classes.titleText}>
              Order Approvals
            </Typography>
            <div tw="flex justify-end items-center gap-4">
              {!triggerCSVDownload && (
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handleOrderSetReport}
                  disabled={
                    showBudgets ||
                    (currentGrouping === "order"
                      ? currentOrders.length === 0
                      : currentItems.length === 0)
                  }
                >
                  GENERATE REPORT
                </Button>
              )}
              {triggerCSVDownload && (
                <CSVLink
                  data={csv.data}
                  headers={csv.headers}
                  filename="rta_approvals.csv"
                >
                  <Button
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    startIcon={<GetAppIcon />}
                  >
                    EXPORT REPORT
                  </Button>
                </CSVLink>
              )}
              {currentGrouping === "order" &&
                !["read-only", "compliance"].includes(currentUserRole) && (
                  <Button
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    disabled={selected.length === 0}
                    style={{ marginRight: "20px" }}
                    onClick={handleBulkApproval}
                  >
                    APPROVE
                  </Button>
                )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              marginBottom: "10px",
            }}
          >
            <HideFiltersButtonToggle />
            <FilterChipList classes={classes} />
            <br />
          </div>

          {currentGrouping === "order" && (
            <OrderApprovalTable
              orders={currentOrders}
              isOrdersLoading={isOrdersLoading}
              handleSort={handleSort}
              scrollRef={scrollRef}
              handleDeny={handleDenyModal}
              selected={selected}
              setSelected={setSelected}
            />
          )}
          {currentGrouping === "item" && (
            <OrderApprovalByItemTable
              items={currentItems}
              isOrdersLoading={isOrdersLoading}
              handleSort={handleSort}
              scrollRef={scrollRef}
              handleDeny={handleDenyModal}
              selected={selected}
              setSelected={setSelected}
            />
          )}
          {isNextLoading && (
            <div style={{ width: "100%" }}>
              <LinearProgress />
            </div>
          )}
          {!isNextLoading && (
            <div style={{ width: "100%", height: "4px" }}></div>
          )}
        </FixedHeightScrollLastChild>
        <OrderPatchLoading />
      </Container>
    </>
  );
};

export default OrderApprovals;
