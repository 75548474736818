import { kebabCaseKeys } from "src/utility/utilityFunctions";

import { buildFilters } from "../../../api/apiFunctions";

/* -------- Query Builders --------- */
export const buildRollupQuery = (filterObject, isForRFP) => {
  const sortMap = {
    itemNumber: "item-number",
    program: "order-program-name",
    itemType: "item-type-description",
    inMarketDate: "in-market-date",
    approvedDate: "order-approved-at",
  };

  let typeBool = `filter[is-for-rfp]=${isForRFP}`;
  let sortString = `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
    sortMap[filterObject.sortOrderBy]
  }`;

  return buildFilters(
    filterObject,
    typeBool,
    sortString,
    "/api/item-rollups",
    "rollup"
  );
};

export const buildPurchaseOrderHistoryQuery = (filterObject) => {
  const sortMap = {
    poNum: "po-id",
    supplier: "supplier-name",
    inMarketDate: "po-in-market-date",
    submittedDate: "po-submitted-at",
    status: "po-status",
  };

  let sortString = `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
    sortMap[filterObject.sortOrderBy]
  }`;

  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/purchase-order-variants",
    "po-history"
  );
};

export const buildRFPHistoryQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    rfpNum: "id",
    program: "program-name",
    itemType: "item-type-description",
    dueDate: "due-date",
    status: "status",
  };

  let sortString = `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
    sortMap[filterObject.sortOrderBy]
  }`;

  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/request-for-prices",
    "rfp-history"
  );
};
/* ---------- ---------- --------- */

export const buildPurchaseOrder = ({ ids, orderType, programId }) => {
  return {
    data: {
      type: "purchase-order",
      attributes: {
        "order-variant-ids": ids,
        "order-type": orderType,
        "program-id": +programId,
      },
    },
  };
};

export const buildInventoryPurchaseOrder = (variants, warehouse, programId) => {
  return {
    data: {
      type: "purchase-order",
      attributes: warehouse
        ? {
            "variant-qtys": variants,
            "program-id": programId,
            warehouse: warehouse,
            type: "to-inventory",
          }
        : {
            "variant-qtys": variants,
            "program-id": programId,
            type: "to-inventory",
          },
    },
  };
};

export const buildAllocationPatch = (id, terrId) => {
  return {
    data: {
      type: "purchase-order-variant",
      id: +id,
      attributes: {
        "allocated-territory-id": terrId,
      },
    },
  };
};

export const buildInventoryFulfillment = (ids, programId, inMarketDate) => {
  return {
    data: {
      attributes: {
        "order-variant-ids": ids,
        "program-id": programId,
        "in-market-date": inMarketDate,
      },
    },
  };
};

export const buildPurchaseOrderPatch = (id, field, value) => {
  return {
    data: {
      type: "purchase-order",
      id: +id,
      attributes: {
        [`${field}`]: value,
      },
    },
  };
};

export const buildUpdateSupplierPatch = (id, supplierId) => {
  return {
    data: {
      type: "purchase-order",
      id: +id,
      relationships: {
        supplier: {
          data: supplierId
            ? {
                type: "supplier",
                id: supplierId,
              }
            : null,
        },
      },
    },
  };
};

export const buildAdditionalFreightPatch = (id, invoiceNumber, cost) => {
  return {
    data: {
      type: "purchase-order",
      id: id,
      attributes: {
        "additional-freight-invoice-number": invoiceNumber,
        "additional-freight-cost": cost,
      },
    },
  };
};

export const buildPOChangeNote = (id, note) => {
  return {
    data: {
      type: "note",
      attributes: {
        message: note,
      },
      relationships: {
        "purchase-order": {
          data: {
            type: "purchase-order",
            id: +id,
          },
        },
      },
    },
  };
};

export const buildNotePatch = (id, note) => {
  return {
    data: {
      type: "note",
      id: id,
      attributes: {
        message: note,
      },
    },
  };
};

export const buildSendToFlowPost = (id, jobId) => {
  return {
    data: {
      id: +id,
      "flow-project-solution-id": +jobId.trim(),
    },
  };
};

export const buildShippingParameterPatch = (id, field, value) => {
  return {
    data: {
      type: "shipping-parameter",
      id: id,
      attributes: {
        [`${field}`]: value,
      },
    },
  };
};

export const buildShippingParameterUpdate = (
  paramId,
  addressId,
  attributes
) => {
  return {
    data: {
      type: "shipping-parameter",
      id: paramId,
      attributes: kebabCaseKeys(attributes),
      ...(addressId && {
        relationships: {
          address: {
            data: {
              type: "address",
              id: +addressId,
            },
          },
        },
      }),
    },
  };
};

export const buildRequestForPrice = (item, program, dueDate, idArray) => {
  return {
    data: {
      type: "request-for-price",
      attributes: {
        "due-date": dueDate,
        "order-variant-ids": idArray,
      },
      relationships: {
        item: {
          data: item
            ? {
                type: "item",
                id: item,
              }
            : null,
        },
        program: {
          data: program
            ? {
                type: "program",
                id: program,
              }
            : null,
        },
      },
    },
  };
};

export const buildRequestForPricePatch = (id, field, value) => {
  return {
    data: {
      type: "request-for-price",
      id: +id,
      attributes: {
        [`${field}`]: value,
      },
    },
  };
};

export const buildSendBidPost = (id, idArray) => {
  const formattedIds = idArray.map((i) => +i);
  return {
    data: {
      id: +id,
      type: "request-for-price",
      supplier_ids: formattedIds,
    },
  };
};

export const buildBidPatch = (id, price, note) => {
  return {
    data: {
      type: "bid",
      id: id,
      attributes: {
        price: price,
        note: note,
      },
    },
  };
};
