import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import max from "date-fns/max";
import _, { omit, sortBy } from "lodash";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms/helpers";

import {
  camelCaseKeys,
  formatDateString,
  stringToCents,
  utcDate,
} from "../../../utility/utilityFunctions";
import { displayId } from "../../../utility/utilityFunctions";
import {
  handleImages,
  handleVariants,
  mapSpecifications,
} from "../../mapHelpers";
import { mapPrograms } from "../programs/maps";

const orderTypeMap = {
  "pre-order": "Pre Order",
  "from-inventory": "Inventory",
  "on-demand": "On Demand",
  "to-inventory": "Restock",
};

const mapAddress = (address) =>
  _.pick(camelCaseKeys(address), [
    "id",
    "name",
    "city",
    "state",
    "country",
    "streetAddress1",
    "streetAddress2",
    "zip",
  ]);

const monthFromDateString = (dateString) =>
  dateString && format(utcDate(dateString), "MMMM");

const handleComplianceStatus = (rules) => {
  if (rules.some((r) => r.status === "denied")) {
    return "Denied";
  }
  if (rules.some((r) => r.status !== "approved" && r.status !== "ok")) {
    return "Pending";
  }
  return "Approved";
};

export const mapOrderSet = (order) => {
  let formattedOrder = {
    id: order.id,
    userId: order.user.id,
    userName: order.user.name,
    orderDate: order["submitted-at"] ? order["submitted-at"] : "---",
    approvedDate: order["approved-at"] ? order["approved-at"] : "---",
    dueDate:
      order["order-calendar-month"] && order.type === "pre-order"
        ? order["order-calendar-month"]["order-window-close-date"]
        : "---",
    inMarketDate:
      order["order-calendar-month"] && order.type === "pre-order"
        ? order["order-calendar-month"]["in-market-start-date"]
          ? order["order-calendar-month"]["in-market-start-date"]
          : "---"
        : "---",
    isOverDue:
      order["order-calendar-month"] &&
      order.type === "pre-order" &&
      order.status === "in-progress"
        ? isBefore(
            addDays(
              endOfDay(
                new Date(order["order-calendar-month"]["purchasing-close-date"])
              ),
              1
            ),
            endOfDay(new Date())
          )
        : false,
    type: orderTypeMap[order.type],
    program: order.program?.brands
      ? mapPrograms([order.program])[0]
      : order.program,
    budgets: order.budgets.map(camelCaseKeys),
    brand:
      order.program && order.program.brands && order.program.brands.length > 0
        ? order.program.brands.map((brand) => brand.name)
        : order["order-set-variants"] && order["order-set-variants"].length > 0
        ? [
            ...new Set(
              [].concat.apply(
                [],
                order["order-set-variants"].map((v) =>
                  v.variant?.item?.brands
                    ? v.variant.item.brands.map((brand) => brand.name)
                    : "---"
                )
              )
            ),
          ]
        : ["---"],

    territoryId: order.territory ? order.territory.id : null,
    territoryName: order.territory ? order.territory.name : "---",
    territories: order["territory-names"] ? order["territory-names"] : "---",
    isAllRegions: order["all-regions"] ? true : false,
    channel: order.channel ? order.channel : null,
    state: order["state-names"] ? order["state-names"] : "---",
    status: order.status,
    orderCount: order["order-count"],
    totalQty: order["total-quantity"],
    totalActCost: "---",
    budget: order.budget ? stringToCents(order.budget) : "$25,000.00",
    hasRush:
      order["order-set-variants"].filter((item) => item["is-rush"]).length > 0,
    totalProductCost:
      order.type === "from-inventory" // don't show item cost for from-inventory orders
        ? 0
        : stringToCents(
            +order["total-actual-cost"]
              ? parseFloat(order["total-actual-cost"])
              : parseFloat(order["total-estimated-cost"])
          ),
    totalEstimatedCost: order["total-estimated-cost"],
    totalEstFreight: order["total-estimated-freight"],
    totalEstTaxes: order["total-estimated-taxes"],
    totalExtendedCost: order["total-extended-cost"],
    items: order["order-set-variants"].map((osv) =>
      camelCaseKeys(osv.variant.item)
    ),
    totalBeaconCost: order["total-beacon-cost"],
    anaplanProgramsLabel: getAnaplanProgramsLabel(order["anaplan-programs"]),
    markedForApproval: order["marked-for-approval"],
  };
  return formattedOrder;
};

export const mapOrderSetHistory = (orders) => orders.map(mapOrderSet);

export const mapOrderSetVariants = (variants) => {
  let mappedVariants = variants.map((variant) => {
    const orderType = variant["order-set-type"];
    return {
      user: variant["user-name"],
      itemNumber: variant["sequence-number"],
      displayId: displayId(variant["sequence-number"], variant["run-number"]),
      territoryName: variant["territory-name"]
        ? variant["territory-name"]
        : "---",
      brand: variant?.variant
        ? variant.variant.item.brands.map((brand) => brand.name)
        : "---",
      program: variant["program-name"],
      itemType: variant["item-type-description"],
      itemDescription: variant?.variant?.item?.comment
        ? variant.variant.item.comment
        : "---",
      state: variant["state-names"] ? variant["state-names"] : "---",
      packSize: variant["qty-per-pack"],
      totalQty: variant["total-item-qty"],
      estCost: stringToCents(variant["estimated-cost"]),
      totalEstCost:
        orderType === "from-inventory"
          ? 0
          : stringToCents(variant["total-estimated-cost"]),
      orderDate: variant["order-set-submitted-at"]
        ? variant["order-set-submitted-at"]
        : "---",
      orderDue: variant["order-calendar-month"]
        ? variant["order-calendar-month"]["order-window-close-date"]
        : "---",
      inMarketDate: variant["order-calendar-month"]
        ? variant["order-calendar-month"]["in-market-start-date"]
          ? variant["order-calendar-month"]["in-market-start-date"]
          : "---"
        : "---",
      isOverDue:
        variant["order-calendar-month"] &&
        variant["order-set-status"] === "in-progress"
          ? isBefore(
              addDays(
                endOfDay(
                  new Date(
                    variant["order-calendar-month"]["purchasing-close-date"]
                  )
                ),
                1
              ),
              endOfDay(new Date())
            )
          : false,
      status: variant["order-set-status"],
      orderSetId: variant["order-set"].id,
      variantName: handleVariants([variant.variant])[0].name,
      totalEstFreight: variant["total-estimated-freight"],
      totalEstTaxes: variant["total-estimated-taxes"],
      totalBeaconCost: variant["total-beacon-cost"],
      totalExtendedCost:
        orderType === "from-inventory"
          ? +variant["total-extended-cost"] - +variant["total-estimated-cost"]
          : variant["total-extended-cost"],
      includeBeacon: variant["include-beacon"],
      budgets: variant.budgets.map(camelCaseKeys),
      anaplanProgramsLabel: getAnaplanProgramsLabel(
        variant["anaplan-programs"]
      ),
    };
  });
  return mappedVariants;
};

export const mapSingleOrder = (order) => {
  let orderVariants = mapOrderVariants(order["order-variants"], "history");
  let formattedOrder = {
    id: order.id,
    user: order.user.name,
    distributorAbn: order.address ? order.address.abn : null,
    address: order.address ? mapAddress(order.address) : null,
    attn: order.attn
      ? order.attn
      : order.address?.["current-user-attn"]
      ? order.address["current-user-attn"]
      : order.address?.["default-attn"]
      ? order.address["default-attn"]
      : "---",
    program:
      order["program-names"] && order["program-names"].length > 0
        ? order["program-names"].join(", ")
        : "---",
    orderType: order.type,
    type: order["order-window-name"] ?? orderTypeMap[order.type],
    brand: [
      ...new Set(
        [].concat.apply(
          [],
          order["order-variants"].map((variant) =>
            variant.variant.item.brands.map((brand) => brand.name)
          )
        )
      ),
    ],
    businessUnit: [
      ...new Set(
        [].concat.apply(
          [],
          order["order-variants"].map((variant) =>
            variant.variant.item.brands.map(
              (brand) => brand["business-unit"].name
            )
          )
        )
      ),
    ],
    variants: orderVariants,
    orderAnaplanSubCodes: [
      ...new Set(
        orderVariants.flatMap((variant) => variant.anaplanSubProgramCodes)
      ),
    ],
    hasCanceledOrDestroyedVariants:
      orderVariants.filter(
        (ov) => ov.isCanceled || ov.isComplianceCanceled || ov.isDestroyed
      ).length > 0,
    status:
      order.status === "submitted"
        ? "Submitted - Pending Approval"
        : order.status,
    orderDate: order["submitted-at"]
      ? format(new Date(order["submitted-at"]), "MM/dd/yyyy")
      : "---",
    approvedDate: order["approved-at"]
      ? format(new Date(order["approved-at"]), "MM/dd/yyyy")
      : "---",
    shipDate:
      orderVariants.length > 0 &&
      orderVariants.filter((variant) => variant.actShipDate === "---")
        .length === 0
        ? format(
            max(orderVariants.map((o) => new Date(o.actShipDate))),
            "MM/dd/yyyy"
          )
        : "---",
    totalQty: order["total-quantity"],
    totalProductCost:
      order.type === "from-inventory" // don't show item cost for from-inventory orders
        ? 0
        : stringToCents(
            +order["total-actual-cost"] === 0
              ? +order["total-estimated-cost"]
              : +order["total-actual-cost"]
          ),

    totalEstFreight: stringToCents(order["total-estimated-shipping-cost"]),
    totalActFreight: stringToCents(order["total-actual-shipping-cost"]),
    totalEstTax: stringToCents(order["total-estimated-tax"]),
    totalActTax: stringToCents(order["total-actual-tax"]),
    totalActCost: stringToCents(order["total-actual-cost"]),
    totalEstExtendedCost: stringToCents(
      +order["total-estimated-shipping-cost"] +
        +order["total-estimated-tax"] +
        +order["total-beacon-cost"] +
        (order.type === "from-inventory" // don't show item cost for from-inventory orders
          ? 0
          : +order["total-actual-cost"] === 0
          ? +order["total-estimated-cost"]
          : +order["total-actual-cost"])
    ),
    totalActExtendedCost: stringToCents(
      +order["total-actual-shipping-cost"] +
        +order["total-actual-tax"] +
        +order["total-beacon-cost"] +
        +order["total-actual-cost"]
    ),

    totalActualCost: order["total-actual-cost"],
    totalActualShippingCost: order["total-actual-shipping-cost"],
    totalBeaconCost: order["total-beacon-cost"],
    totalEstimatedCost: order["total-estimated-cost"],
    totalEstimatedShippingCost: order["total-estimated-shipping-cost"],
    totalEstimatedTax: order["total-estimated-tax"],

    billableTotalActualCost: order["billable-total-actual-cost"],
    billableTotalActualShippingCost:
      order["billable-total-actual-shipping-cost"],
    billableTotalBeaconCost: order["billable-total-beacon-cost"],
    billableTotalEstimatedCost: order["billable-total-estimated-cost"],
    billableTotalEstimatedShippingCost:
      order["billable-total-estimated-shipping-cost"],
    billableTotalEstimatedTax: order["billable-total-estimated-tax"],

    note: order.notes ? order.notes : "---",
    territoryName: order["territory-name"] ? order["territory-name"] : "---",
    subStateName: order["sub-state"]?.name,
    territoryId: order.territory?.id,
    subStateId: order["sub-state"]?.id,
    toInventoryAllocation:
      "Restock: " +
      (order["sub-state"]
        ? order["sub-state"].name
        : order.territory
        ? order["territory-name"]
        : "National"),
    approvalLogging: order["approval-logging"] ?? "---",
    anaplanProgramTypes: order["anaplan-program-types"] ?? [],
  };
  return formattedOrder;
};

export const mapOrderHistoryOrders = (orders) => orders.map(mapSingleOrder);

export const mapOrderHistoryVariants = (variants) => {
  let mappedVariants = variants.map((variant) => {
    const images = handleImages(variant.variant.item.images);
    return {
      id: variant.id,
      itemNumber: variant["item-number"],
      displayId: displayId(variant["item-number"], variant["run-number"]),
      imgUrlThumb: images.imgUrlThumb,
      imgUrlLg: images.imgUrlLg,
      orderType:
        variant["order-window-name"] ?? orderTypeMap[variant["order-type"]],
      orderNumber: variant.order.id,
      specification: variant.variant.item.specification
        ? mapSpecifications(variant.variant.item.specification, "item-display")
        : null,
      brand: variant.variant.item.brands.map((brand) => brand.name),
      businessUnit: variant.variant.item.brands.map(
        (brand) => brand["business-unit"].name
      ),
      brandCode: variant.variant.item.brands
        .map((brand) => brand["external-id"])
        .join(", "),
      program: variant["program-names"].join(", "),
      itemType: variant["item-type-description"],
      itemTypeCode: variant["item-type-external-id"]
        ? variant["item-type-external-id"]
        : "---",
      itemDescription: variant.variant.item.comment
        ? variant.variant.item.comment
        : "---",
      unit: [
        ...new Set(
          variant.variant.item.brands.map(
            (brand) => brand["business-unit"].name
          )
        ),
      ].join(", "),
      addressName: variant["address-name"]
        ? variant["address-name"].replace(/,/g, "")
        : "---",
      distributorAbn: variant["address-abn"] ? variant["address-abn"] : "---",
      addressOne: variant["street-address-1"],
      addressTwo: variant["street-address-2"],
      city: variant.city,
      state: variant.state ? variant.state : "---",
      zip: variant.zip,
      supplierId: variant.variant.item.supplier?.id,
      territoryType: variant["territory-type"]
        ? variant["territory-type"]
        : "---",
      territoryName: variant["territory-name"]
        ? variant["territory-name"]
        : "---",
      packSize: variant["qty-per-pack"],
      totalQty: variant.qty,
      estCost: stringToCents(variant["estimated-cost"]),

      totalProductCost:
        variant["order-type"] === "from-inventory" // don't show item cost for from-inventory orders
          ? 0
          : variant["total-actual-cost"] !== "0.0"
          ? stringToCents(parseFloat(variant["total-actual-cost"]))
          : variant["total-estimated-cost"] !== "0.0"
          ? stringToCents(parseFloat(variant["total-estimated-cost"]))
          : "---",

      totalEstFreight: stringToCents(variant["total-estimated-shipping-cost"]),

      totalEstTax: stringToCents(variant["total-estimated-tax"]),

      totalEstCost:
        variant["total-estimated-shipping-cost"] &&
        variant["total-estimated-tax"] &&
        variant["total-actual-cost"] !== "0.0"
          ? stringToCents(
              parseFloat(variant["total-estimated-shipping-cost"]) +
                parseFloat(variant["total-estimated-tax"]) +
                parseFloat(variant["total-beacon-cost"]) +
                (variant["order-type"] === "from-inventory"
                  ? 0
                  : parseFloat(variant["total-actual-cost"]))
            )
          : variant["total-estimated-shipping-cost"] &&
            variant["total-estimated-tax"] &&
            variant["total-estimated-cost"] !== "0.0"
          ? stringToCents(
              parseFloat(variant["total-estimated-shipping-cost"]) +
                parseFloat(variant["total-estimated-tax"]) +
                parseFloat(variant["total-beacon-cost"]) +
                (variant["order-type"] === "from-inventory"
                  ? 0
                  : parseFloat(variant["total-estimated-cost"]))
            )
          : "---",

      actCost:
        variant["total-actual-cost"] && variant.qty > 0
          ? stringToCents(variant["total-actual-cost"]) / variant.qty
          : variant["total-actual-cost"]
          ? stringToCents(variant["total-actual-cost"])
          : "---",
      totalActCost: variant["total-actual-cost"]
        ? stringToCents(variant["total-actual-cost"])
        : "---",

      totalActualCost: variant["total-actual-cost"],
      totalActualShippingCost: variant["total-actual-shipping-cost"],
      totalBeaconCost: variant["total-beacon-cost"],
      totalEstimatedCost: variant["total-estimated-cost"],
      totalEstimatedShippingCost: variant["total-estimated-shipping-cost"],
      totalEstimatedTax: variant["total-estimated-tax"],

      billableTotalActualCost: variant["billable-total-actual-cost"],
      billableTotalActualShippingCost:
        variant["billable-total-actual-shipping-cost"],
      billableTotalBeaconCost: variant["billable-total-beacon-cost"],
      billableTotalEstimatedCost: variant["billable-total-estimated-cost"],
      billableTotalEstimatedShippingCost:
        variant["billable-total-estimated-shipping-cost"],
      billableTotalEstimatedTax: variant["billable-total-estimated-tax"],

      orderDate: variant["order-submitted-at"]
        ? format(new Date(variant["order-submitted-at"]), "MM/dd/yyyy")
        : "---",
      estShipDate: variant["po-in-market-date"]
        ? formatDateString(variant["po-in-market-date"])
        : null,
      poId: variant["po-id"] ?? null,
      includeBeacon: variant["po-id"]
        ? variant["po-include-beacon"]
          ? "Y"
          : "N"
        : "",
      shipDate:
        variant["shipping-parameter-variant"] &&
        variant["shipping-parameter-variant"]["actual-ship-date"]
          ? formatDateString(
              variant["shipping-parameter-variant"]["actual-ship-date"]
            )
          : variant["actual-ship-date"]
          ? formatDateString(variant["actual-ship-date"])
          : "---",
      carrier:
        variant["tracking-data"] && variant["tracking-data"].carrier
          ? variant["tracking-data"].carrier
          : "---",
      tracking:
        variant["tracking-data"] && variant["tracking-data"].number
          ? variant["tracking-data"].number
          : "---",
      isTransferred: variant["is-transferred"],
      status: variant["order-status"],
      user: variant["order-user-name"],
      removeFromCatalogDate: variant.variant.item["remove-from-catalog-date"],
      inMarketDate: variant["in-market-date"]
        ? formatDateString(variant["in-market-date"])
        : "---",
      inMarketMonth:
        monthFromDateString(variant["in-market-date"]) ??
        monthFromDateString(variant["po-in-market-date"]) ??
        "---",
      isNotCompliant:
        !variant["overrides-compliance"] &&
        (variant["triggered-rules"] ?? []).some(
          (rule) => rule.status !== "approved"
        ),
      complianceStatus: variant["overrides-compliance"]
        ? "Compliance Overridden"
        : handleComplianceStatus([
            ...variant["triggered-rules"],
            ...variant["prior-approval-triggered-rules"],
          ]),
      triggeredRules: variant["triggered-rules"]
        ? variant["triggered-rules"].map((rule) => ({
            type: rule["rule-type"],
            description: rule["rule-description"],
          }))
        : [],
      triggeredPriorApprovalRules: variant["prior-approval-triggered-rules"]
        ? variant["prior-approval-triggered-rules"].map((rule) => ({
            type: rule["rule-type"],
            description: rule["rule-description"],
          }))
        : [],
      isComplianceCanceled:
        variant.status === "canceled" &&
        variant["cancelation-type"] === "compliance",
      isCanceled:
        variant.status === "canceled" &&
        variant["cancelation-type"] === "order",
      canceledNote:
        variant.status === "canceled" && variant["cancelation-note"]
          ? variant["cancelation-note"]
          : null,
      isDestroyed: variant.status === "destroyed",
      orderId: variant.order.id,
      anaplanSubProgramCodes: variant["anaplan-sub-program-codes"],
      isCoupon: variant.variant.item["is-coupon"],
      couponInfo: variant.variant.item["coupon-custom-raw-payload"]
        ? {
            startDate: formatDateString(
              variant.variant.item["coupon-custom-raw-payload"].CouponStartDate
            ),
            expirationDate: formatDateString(
              variant.variant.item["coupon-custom-raw-payload"]
                .CouponExpirationDate
            ),
            typeCode:
              variant.variant.item["coupon-custom-raw-payload"].CouponTypeCode,
            offerType:
              variant.variant.item["coupon-custom-raw-payload"]
                .CouponOfferTypeCode,
            description:
              variant.variant.item["coupon-custom-raw-payload"]
                .CouponOfferDescription,
            bottles: variant.variant.item["coupon-custom-raw-payload"].Quantity,
            bottleDiscount:
              variant.variant.item["coupon-custom-raw-payload"]
                .CouponFaceValue * 100,
          }
        : null,
      couponStartDate: variant.variant.item["coupon-issue-date"]
        ? formatDateString(variant.variant.item["coupon-issue-date"])
        : null,
      couponExpirationDate: variant.variant.item["coupon-expiration-date"]
        ? formatDateString(variant.variant.item["coupon-expiration-date"])
        : null,
      couponOfferType: variant.variant.item["coupon-offer-type-code"]
        ? variant.variant.item["coupon-offer-type-code"]
        : null,
      couponOfferDescription: variant.variant.item["coupon-offer-description"]
        ? variant.variant.item["coupon-offer-description"]
        : null,
      couponTypeCode: variant.variant.item["coupon-type-code"]
        ? variant.variant.item["coupon-type-code"]
        : null,
      variantName: handleVariants([variant.variant])[0].name,
      itemTypePosType: variant.variant.item["item-type-pos-type"],

      approvalLogging: variant["approval-logging"] ?? "---",
      anaplanProgramTypes: variant["anaplan-program-types"] ?? [],
    };
  });
  return mappedVariants;
};

export const mapOrderVariants = (variants, type) => {
  let mappedVariants = variants
    .map((variant) => {
      const images = handleImages(variant.variant.item.images);
      const itemNumber =
        variant["item-number"] || variant.variant["variant-sku"];
      const rn = variant["run-number"];
      return {
        id: variant.id,
        itemId: variant.variant.item.id,
        variantId: variant.variant.id,
        itemNumber,
        displayId: displayId(itemNumber, rn),
        imgUrlThumb: images.imgUrlThumb,
        imgUrlLg: images.imgUrlLg,
        brand:
          variant.variant.item.brands.length > 0
            ? variant.variant.item.brands.map((brand) => brand.name).join(", ")
            : "---",
        specification: variant.variant.item.specification
          ? mapSpecifications(
              variant.variant.item.specification,
              "item-display"
            )
          : null,
        brandCode: variant.variant.item.brands
          .map((brand) => brand["external-id"])
          .join(", "),
        program: variant["program-names"]
          ? variant["program-names"].join(", ")
          : variant.variant.item.programs.map((prog) => prog.name).join(", "),
        programs: sortBy(variant.variant.item.programs, "start-date")
          .reverse()
          .map((prog) => ({
            id: prog.id,
            name: prog.name,
            isTemporaryForCoupon: prog["is-temporary-for-coupon"],
          })),
        itemType: variant.variant.item.type,
        itemDescription: variant.variant.item.comment
          ? variant.variant.item.comment
          : "---",
        unit:
          variant.variant.item.brands.length > 0
            ? [
                ...new Set(
                  variant.variant.item.brands.map(
                    (brand) => brand["business-unit"].name
                  )
                ),
              ].join(", ")
            : "---",
        packSize: variant.variant.item["qty-per-pack"],
        leadTime: variant.variant.item["lead-time-in-days"],
        supplierId: variant.variant.item.supplier.id,
        state:
          type === "order-set-variant"
            ? "---"
            : variant.order.address
            ? variant.order.address.state
            : "---",
        estCost: stringToCents(variant["estimated-cost"]),
        totalQty: type === "order-set-variant" ? 0 : variant.qty,
        totalEstShipping:
          type === "order-set-variant"
            ? null
            : stringToCents(variant["total-estimated-shipping-cost"]),
        totalEstTax:
          type === "order-set-variant"
            ? null
            : stringToCents(variant["total-estimated-tax"]),
        totalEstCost:
          type === "order-set-variant"
            ? 0
            : stringToCents(variant["total-estimated-cost"]),
        totalActCost: stringToCents(variant["total-actual-cost"]),
        complianceStatus:
          type !== "order-set-variant" &&
          variant["triggered-rules"].length > 0 &&
          variant["triggered-rules"].filter(
            (rule) =>
              (rule.type === "item-type" ||
                rule.type === "metal-wood" ||
                rule.type === "coupon-item-type" ||
                rule.type === "coupon-offer-type" ||
                rule.type === "coupon-face-vale") &&
              rule.status !== "approved"
          ).length > 0
            ? "not-compliant"
            : "compliant",
        complianceDisplay:
          type !== "order-set-variant"
            ? variant["overrides-compliance"]
              ? "Compliance Overridden"
              : handleComplianceStatus([
                  ...variant["triggered-rules"],
                  ...variant["prior-approval-triggered-rules"],
                ])
            : null,
        priorApprovalDenied:
          type !== "order-set-variant" && variant["prior-approval-denied"]
            ? variant["prior-approval-denied"]
            : false,
        isComplianceCanceled:
          variant.status === "canceled" &&
          variant["cancelation-type"] === "compliance",
        isCanceled:
          variant.status === "canceled" &&
          variant["cancelation-type"] === "order",
        canceledNote:
          variant.status === "canceled" && variant["cancelation-note"]
            ? variant["cancelation-note"]
            : null,
        isDestroyed: variant.status === "destroyed",
        orderType: variant["order-type"],
        itemOrderType: variant.variant.item["item-order-type"],
        removeFromCatalogDate: variant.variant.item["remove-from-catalog-date"],
        standardDeliveryDate: variant["standard-delivery-date"]
          ? variant["standard-delivery-date"]
          : "---",
        inMarketDate: variant["in-market-date"]
          ? formatDateString(variant["in-market-date"])
          : variant["standard-delivery-date"] && type === "order-set-variant"
          ? variant["standard-delivery-date"]
          : "---",
        inMarketMonth:
          monthFromDateString(variant["in-market-date"]) ??
          monthFromDateString(variant["po-in-market-date"]) ??
          "---",
        isRush: variant["is-rush"] ? variant["is-rush"] : false,
        estShipDate: variant["po-in-market-date"]
          ? formatDateString(variant["po-in-market-date"])
          : null,
        poId: variant["po-id"] ? variant["po-id"] : "---",
        actShipDate:
          variant["shipping-parameter-variant"] &&
          variant["shipping-parameter-variant"]["actual-ship-date"]
            ? formatDateString(
                variant["shipping-parameter-variant"]["actual-ship-date"]
              )
            : variant["actual-ship-date"]
            ? formatDateString(variant["actual-ship-date"])
            : "---",
        carrier:
          variant["tracking-data"] && variant["tracking-data"].carrier
            ? variant["tracking-data"].carrier
            : "---",
        tracking:
          variant["tracking-data"] && variant["tracking-data"].number
            ? variant["tracking-data"].number
            : "---",
        isTransferred: variant["is-transferred"],
        onShipHold: variant["shipping-parameter-variant"]
          ? variant["shipping-parameter-variant"]["compliance-status"] ===
              "prior-approval-pending" ||
            variant["shipping-parameter-variant"]["compliance-status"] ===
              "denied"
          : "---",
        isCoupon: variant.variant.item["is-coupon"],
        couponInfo: variant.variant.item["coupon-custom-raw-payload"]
          ? {
              startDate: formatDateString(
                variant.variant.item["coupon-custom-raw-payload"]
                  .CouponStartDate
              ),
              expirationDate: formatDateString(
                variant.variant.item["coupon-custom-raw-payload"]
                  .CouponExpirationDate
              ),
              typeCode:
                variant.variant.item["coupon-custom-raw-payload"]
                  .CouponTypeCode,
              offerType:
                variant.variant.item["coupon-custom-raw-payload"]
                  .CouponOfferTypeCode,
              description:
                variant.variant.item["coupon-custom-raw-payload"]
                  .CouponOfferDescription,
              bottles:
                variant.variant.item["coupon-custom-raw-payload"].Quantity,
              bottleDiscount:
                variant.variant.item["coupon-custom-raw-payload"]
                  .CouponFaceValue * 100,
            }
          : null,
        couponStartDate: variant.variant.item["coupon-issue-date"]
          ? formatDateString(variant.variant.item["coupon-issue-date"])
          : null,
        couponExpirationDate: variant.variant.item["coupon-expiration-date"]
          ? formatDateString(variant.variant.item["coupon-expiration-date"])
          : null,
        couponOfferType: variant.variant.item["coupon-offer-type-code"]
          ? variant.variant.item["coupon-offer-type-code"]
          : null,
        couponOfferDescription: variant.variant.item["coupon-offer-description"]
          ? variant.variant.item["coupon-offer-description"]
          : null,
        couponTypeCode: variant.variant.item["coupon-type-code"]
          ? variant.variant.item["coupon-type-code"]
          : null,
        variantName: handleVariants([variant.variant])[0].name,
        moq: variant.moq ?? "---",
        includeBeacon: variant.variant.item["include-beacon"],
        mostRecentProgramId: variant.variant.item["most-recent-program-id"],
        mostRecentBeaconCost: variant.variant.item["most-recent-beacon-cost"],
        anaplanSubProgramCodes: variant["anaplan-sub-program-codes"],

        totalActualCost: variant["total-actual-cost"],
        totalActualShippingCost: variant["total-actual-shipping-cost"],
        totalBeaconCost: variant["total-beacon-cost"],
        totalEstimatedCost: variant["total-estimated-cost"],
        totalEstimatedShippingCost: variant["total-estimated-shipping-cost"],
        totalEstimatedTax: variant["total-estimated-tax"],

        billableTotalActualCost: variant["billable-total-actual-cost"],
        billableTotalActualShippingCost:
          variant["billable-total-actual-shipping-cost"],
        billableTotalBeaconCost: variant["billable-total-beacon-cost"],
        billableTotalEstimatedCost: variant["billable-total-estimated-cost"],
        billableTotalEstimatedShippingCost:
          variant["billable-total-estimated-shipping-cost"],
        billableTotalEstimatedTax: variant["billable-total-estimated-tax"],
      };
    })
    .sort((a, b) => {
      return parseInt(a.variantId) < parseInt(b.variantId)
        ? -1
        : parseInt(a.variantId) > parseInt(b.variantId)
        ? 1
        : 0;
    });
  return mappedVariants;
};

export const mapOrderVariantReport = (orderVariants) =>
  orderVariants.map((ov) => ({
    ...camelCaseKeys(
      omit(
        ov,
        "variant",
        "triggered-rules",
        "shipping-parameter-variant",
        "prior-approval-triggered-rules",
        "order"
      )
    ),
    brands: ov.variant.item.brands.map(({ name }) => name),
    bus: ov.variant.item.brands.map((brand) => brand["business-unit"]?.name),
    itemName: ov.variant.item.name,
    addressIdOrAbn: ov["address-abn"] ?? "c" + ov["address-id"],
    totalExtendedCost:
      +ov["total-estimated-cost"] +
      +ov["total-estimated-shipping-cost"] +
      +ov["total-estimated-tax"] +
      +ov["total-beacon-cost"],
    itemDescription: ov?.variant?.item?.comment,
    anaplanProgramsLabel: getAnaplanProgramsLabel(ov["anaplan-programs"] ?? []),
  }));
