/** @jsxImportSource @emotion/react */
import "twin.macro";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { OrderCosts } from "./helpers";

export const DisplayBillable = ({
  billable,
  estimate,
}: {
  billable: number | string;
  estimate: number | string;
}) => {
  if (+billable !== +estimate) {
    return (
      <span>
        <span tw="line-through opacity-50">
          &nbsp;{formatMoneyString(estimate)}&nbsp;
        </span>
        <span tw="pl-0.5">{formatMoneyString(billable)}</span>
      </span>
    );
  } else {
    return <span>{formatMoneyString(billable)}</span>;
  }
};

export const BillableItemCost = ({ costs }: { costs: OrderCosts }) => {
  const billable =
    +costs.billableTotalActualCost || +costs.billableTotalEstimatedCost;
  const estimate = +costs.totalActualCost || +costs.totalEstimatedCost;

  return <DisplayBillable billable={billable} estimate={estimate} />;
};

export const BillableBeaconCost = ({ costs }: { costs: OrderCosts }) => {
  const billable = +costs.billableTotalBeaconCost;
  const estimate = +costs.totalBeaconCost;

  return <DisplayBillable billable={billable} estimate={estimate} />;
};

export const BillableShippingCost = ({ costs }: { costs: OrderCosts }) => {
  const billable =
    +costs.billableTotalActualShippingCost ||
    +costs.billableTotalEstimatedShippingCost;
  const estimate =
    +costs.totalActualShippingCost || +costs.totalEstimatedShippingCost;

  return <DisplayBillable billable={billable} estimate={estimate} />;
};

export const BillableTaxCost = ({ costs }: { costs: OrderCosts }) => {
  const billable = +costs.billableTotalEstimatedTax;
  const estimate = +costs.totalEstimatedTax;

  return <DisplayBillable billable={billable} estimate={estimate} />;
};
