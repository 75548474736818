import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { capitalize } from "lodash";
import PropTypes from "prop-types";

import { BillableItemCost, getExtendedCost } from "@features/orders";
import {
  BillableBeaconCost,
  BillableShippingCost,
  BillableTaxCost,
} from "@features/orders/BillableCosts";

import {
  clearOrderHistoryReports,
  setTriggerCSVFalse,
} from "../../redux/slices/ordering/orderHistorySlice";
import { formatMoney, formatMoneyString } from "../../utility/utilityFunctions";
import ImageWrapper from "../Utility/ImageWrapper";
import CancelInfoModal from "../Utility/Modals/CancelInfoModal";

const headCells = [
  { id: "preview", label: "Preview" },
  { id: "itemNumber", label: "Sequence #", sort: true },
  {
    id: "orderType",
    label: "Order Type / Window",
    sort: true,
  },
  {
    id: "inMarketMonth",
    label: "In Market Month",
    sort: true,
  },
  { id: "orderId", label: "Order #", sort: true },
  { id: "anaplanSubProgramCodes", label: "12 digit code" },
  { id: "anaplanProgramTypes", label: "Anplan Program Type" },
  { id: "poId", label: "Purchase Order #" },
  { id: "user", label: "Ordered By" },
  { id: "brand", label: "Brand", sort: true },
  { id: "businessUnit", disablePadding: false, label: "BU", sort: true },
  { id: "program", label: "Program" },
  { id: "itemType", label: "Item Type", sort: true },
  { id: "variantName", label: "Variant" },
  {
    id: "itemDescription",
    label: "Item Desc.",
  },
  {
    id: "addressName",
    label: "Distributor / Name",
    sort: true,
  },
  {
    id: "distributorAbn",
    label: "Address ID",
    sort: true,
  },
  { id: "state", label: "State" },
  { id: "territoryName", label: "Territory" },
  {
    id: "totalQty",
    label: "Total Qty",
  },
  {
    id: "estCost",
    label: "Est. Cost/Unit",
  },
  {
    id: "actCost",
    label: "Act. Cost/Unit",
  },
  {
    id: "totalProductCost",
    label: "Total Product Cost",
  },
  { id: "totalEstFreight", label: "Est. Freight" },
  { id: "totalEstTax", label: "Est. Taxes" },
  { id: "totalBeaconCost", label: "Total Beacon Cost" },
  { id: "totalEstCost", label: "Est. Total Cost" },
  { id: "orderDate", label: "Order Date", sort: true },
  {
    id: "estShipDate",
    label: "In Market Date",
  },
  {
    id: "shipDate",
    label: "Act. Ship Date",
    sort: true,
  },
  { id: "carrier", label: "Carrier" },
  { id: "tracking", label: "Tracking #" },
  { id: "status", label: "Order Status", sort: true },
  {
    id: "complianceStatus",
    label: "Compliance Status",
    sort: true,
  },
  {
    id: "includeBeacon",
    label: "Beacon",
  },
  {
    id: "approvalLogging",
    disablePadding: false,
    label: "Approval Logs",
    sort: false,
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, role } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const filteredHeadCells = [
    "super",
    "purchaser",
    "select-purchaser",
    "read-only",
    "compliance",
  ].includes(role)
    ? headCells
    : headCells.filter((cell) => cell.id !== "poId");

  return (
    <TableHead>
      <TableRow>
        {filteredHeadCells.map((headCell) => {
          if (!headCell.sort) {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
              >
                {headCell.label}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OrderHistoryByVariantTable = ({
  items,
  handleSort,
  isOrdersLoading,
  scrollRef,
  itemRef,
  handlePreview,
  handleTrackingClick,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("itemType");
  const [isCancelInfoOpen, setCancelInfoOpen] = useState(false);
  const [cancelInfo, setCancelInfo] = useState(null);
  const role = useSelector((state) => state.user.role);

  const handleClose = () => {
    setCancelInfo(null);
    setCancelInfoOpen(false);
  };

  const handleOpen = (info) => {
    setCancelInfo(info);
    setCancelInfoOpen(true);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleRowClick = (orderNum) => {
    navigate(`/orders/history/single-order/${orderNum}`);
  };

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearOrderHistoryReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isCancelInfoOpen && (
        <CancelInfoModal
          open={isCancelInfoOpen}
          handleClose={handleClose}
          info={cancelInfo}
        />
      )}
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          style={{ minWidth: "1325px" }}
          ref={itemRef}
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            role={role}
          />
          <TableBody>
            {!isOrdersLoading && items.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={14}>
                  <Typography className={classes.headerText}>
                    {`You currently don't have any items on record that match this search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isOrdersLoading &&
              items.length > 0 &&
              items.map((row, index) => {
                const DefaultTableCell = ({ children, ...props }) => (
                  <TableCell
                    className={
                      row.isComplianceCanceled || row.isCanceled
                        ? classes.cancelCell
                        : null
                    }
                    {...props}
                  >
                    {children}
                  </TableCell>
                );
                const combinedRules = [
                  ...row.triggeredRules,
                  ...row.triggeredPriorApprovalRules,
                ];

                return (
                  <TableRow
                    key={index}
                    hover
                    className={
                      row.isComplianceCanceled || row.isCanceled
                        ? classes.cancelRow
                        : classes.orderHistoryRow
                    }
                    onClick={() => {
                      handleRowClick(row.orderId);
                    }}
                  >
                    <DefaultTableCell>
                      <ImageWrapper
                        id={row.itemNumber}
                        imgClass={classes.previewImageFloat}
                        alt={row.itemType}
                        imgUrl={row.imgUrlThumb}
                        handleClick={(evt) => {
                          evt.stopPropagation();
                          handlePreview(row.itemNumber);
                        }}
                      />
                    </DefaultTableCell>
                    <DefaultTableCell>{row.itemNumber}</DefaultTableCell>
                    <DefaultTableCell>{row.orderType}</DefaultTableCell>
                    <DefaultTableCell>{row.inMarketMonth}</DefaultTableCell>
                    <DefaultTableCell>{row.orderId}</DefaultTableCell>
                    <DefaultTableCell>
                      {row.anaplanSubProgramCodes.join(" ")}
                    </DefaultTableCell>
                    <DefaultTableCell>
                      {row.anaplanProgramTypes.join(" ")}
                    </DefaultTableCell>
                    {[
                      "super",
                      "purchaser",
                      "select-purchaser",
                      "read-only",
                      "compliance",
                    ].includes(role) && (
                      <DefaultTableCell>{row.poId}</DefaultTableCell>
                    )}
                    <DefaultTableCell>{row.user}</DefaultTableCell>
                    {row.brand.length > 1 ? (
                      <DefaultTableCell>
                        <Typography variant="body2">Multiple Brands</Typography>
                        <Tooltip
                          placement="left"
                          title={`${row.brand.join(", ")}`}
                        >
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </Tooltip>
                      </DefaultTableCell>
                    ) : (
                      <DefaultTableCell>{row.brand[0]}</DefaultTableCell>
                    )}
                    <DefaultTableCell>
                      {row.businessUnit.join(" ")}
                    </DefaultTableCell>
                    {row.program.split(", ").length > 1 ? (
                      <DefaultTableCell>
                        <Typography variant="body2">
                          {row.program.split(", ")[0]}
                        </Typography>
                        <Tooltip placement="left" title={row.program}>
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </Tooltip>
                      </DefaultTableCell>
                    ) : (
                      <DefaultTableCell>{row.program}</DefaultTableCell>
                    )}
                    <DefaultTableCell style={{ whiteSpace: "nowrap" }}>
                      {row.itemType}
                    </DefaultTableCell>
                    <DefaultTableCell style={{ whiteSpace: "nowrap" }}>
                      {row.variantName ?? "---"}
                    </DefaultTableCell>
                    <DefaultTableCell>{row.itemDescription}</DefaultTableCell>
                    <DefaultTableCell>
                      {row.addressName.length > 0 ? row.addressName : "---"}
                    </DefaultTableCell>
                    <DefaultTableCell>
                      {row.distributorAbn ? row.distributorAbn : "---"}
                    </DefaultTableCell>
                    <DefaultTableCell>{row.state}</DefaultTableCell>
                    <DefaultTableCell>{row.territoryName}</DefaultTableCell>
                    <DefaultTableCell>
                      {row.isComplianceCanceled || row.isCanceled
                        ? row.isCanceled
                          ? "Canceled"
                          : "Compliance Canceled"
                        : row.totalQty}
                    </DefaultTableCell>
                    <DefaultTableCell>
                      {row.estCost !== "---"
                        ? formatMoney(row.estCost, false)
                        : row.estCost}
                    </DefaultTableCell>
                    <DefaultTableCell>
                      {row.actCost !== "---"
                        ? formatMoney(row.actCost, false)
                        : row.actCost}
                    </DefaultTableCell>
                    <DefaultTableCell align="right">
                      <BillableItemCost costs={row} />
                    </DefaultTableCell>
                    <DefaultTableCell align="right">
                      <BillableShippingCost costs={row} />
                    </DefaultTableCell>
                    <DefaultTableCell align="right">
                      <BillableTaxCost costs={row} />
                    </DefaultTableCell>
                    <DefaultTableCell align="right">
                      <BillableBeaconCost costs={row} />
                    </DefaultTableCell>
                    <DefaultTableCell align="right">
                      {formatMoneyString(getExtendedCost(row))}
                    </DefaultTableCell>
                    <DefaultTableCell>{row.orderDate}</DefaultTableCell>
                    <DefaultTableCell>
                      {row.estShipDate ?? row.inMarketDate}
                    </DefaultTableCell>
                    <DefaultTableCell>{row.shipDate}</DefaultTableCell>
                    <DefaultTableCell>{row.carrier}</DefaultTableCell>
                    <DefaultTableCell
                      align="center"
                      className={
                        row.tracking !== "---" &&
                        !row.isComplianceCanceled &&
                        !row.isCanceled
                          ? classes.clickableCell
                          : null
                      }
                      onClick={
                        row.tracking !== "---" &&
                        !row.isComplianceCanceled &&
                        !row.isCanceled
                          ? (evt) => {
                              evt.stopPropagation();
                              handleTrackingClick(row.id);
                            }
                          : null
                      }
                    >
                      {row.isTransferred
                        ? "Transferred qty’s from other territories"
                        : row.tracking}
                    </DefaultTableCell>
                    <DefaultTableCell>
                      {!row.isComplianceCanceled &&
                      !row.isCanceled &&
                      !row.isDestroyed ? (
                        row.status[0].toUpperCase() + row.status.slice(1)
                      ) : row.isDestroyed ? (
                        "Destroyed"
                      ) : row.isCanceled ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{ fontSize: "14px" }}>
                            Canceled
                          </Typography>
                          <IconButton
                            onClick={(evt) => {
                              evt.stopPropagation();
                              handleOpen(row.canceledNote);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{ fontSize: "14px" }}>
                            Compliance Canceled
                          </Typography>
                          <IconButton
                            onClick={(evt) => {
                              evt.stopPropagation();
                              handleOpen(row.canceledNote);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </div>
                      )}
                    </DefaultTableCell>

                    <DefaultTableCell>
                      {row.complianceStatus.includes("Overridden") &&
                      combinedRules.length > 0
                        ? `(${combinedRules
                            .map((ruleObj) => capitalize(ruleObj.type))
                            .join(", ")}) `
                        : ""}
                      {row.complianceStatus}
                    </DefaultTableCell>

                    <DefaultTableCell>{row.includeBeacon}</DefaultTableCell>
                    <DefaultTableCell>{row.approvalLogging}</DefaultTableCell>
                  </TableRow>
                );
              })}
            {isOrdersLoading && (
              <TableRow>
                <TableCell align="left" colSpan={11}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

OrderHistoryByVariantTable.propTypes = {
  orders: PropTypes.array,
  handleSort: PropTypes.func.isRequired,
  isOrdersLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any.isRequired,
};

export default OrderHistoryByVariantTable;
