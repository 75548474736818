/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useContext } from "react";
import { useParams } from "react-router-dom";

import _ from "lodash";
import { SmartTable } from "src/components/SmartTable";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { displayOrderSetStatus, getExtendedCost } from "../helpers";
import OrderSetIdContext from "../orderSet/data/OrderSetIDContext";
import { useOrderSet } from "../orderSet/data/useOrderSet";
import useOrderWindowPrograms from "./usePreOrderPrograms";

const PreOrderProgramsSummary = () => {
  const { orderWindowId } = useParams();

  // We're outside of the orderSetContext so orderSetId may be null
  const orderSetId = useContext(OrderSetIdContext);
  const { orders } = useOrderSet(orderSetId);

  const liveOrderSetExtendedTotal = (orders ?? []).reduce(
    (total, order) => total + getExtendedCost(order),
    0
  );
  const { data } = useOrderWindowPrograms(orderWindowId as string);

  const rows = _(data)
    .flatMap((d) => (d.orderSet ? [d.orderSet] : []))
    .map((os) => ({
      ...os,
      extendedEstimatedCost:
        os.id === orderSetId
          ? liveOrderSetExtendedTotal
          : +os.totalExtendedCost,
    }))
    .sortBy("program.primaryBrand.id")
    .value();

  const totalQuarterlySpend = rows.reduce(
    (total, orderSet) => total + orderSet.extendedEstimatedCost,
    0
  );

  return (
    <>
      <div tw="rounded bg-white overflow-hidden shadow-sm">
        <SmartTable
          noResultsText="No orders to show"
          error={null}
          rows={rows}
          columns={[
            {
              id: "_programName",
              label: "Pre-Order Program",
              render: (_, row) =>
                `${
                  row.program?.brands.length === 1
                    ? row.program?.brands[0].name
                    : "Multi Brand"
                } - ${row.program!.name}`,
            },
            {
              id: "status",
              label: "Status",
              render: displayOrderSetStatus,
            },
            {
              id: "totalQuantity",
              label: "Total Items",
            },
            {
              id: "extendedEstimatedCost",
              label: "Total Est Cost",
              align: "right",
              render: formatMoneyString,
            },
          ]}
        />
      </div>
      <div tw="flex justify-end mt-4 mr-4 text-lg">
        Total Quarterly Spend: {formatMoneyString(totalQuarterlySpend)}
      </div>
    </>
  );
};

export default PreOrderProgramsSummary;
