import { Order, OrderSet, OrderSetStatus, Variant } from "@models";

// fields shared by Order and OrderVariant
export type OrderCosts = Pick<
  Order,
  | "totalActualCost"
  | "totalActualShippingCost"
  | "totalBeaconCost"
  | "totalEstimatedCost"
  | "totalEstimatedShippingCost"
  | "totalEstimatedTax"
  | "billableTotalActualCost"
  | "billableTotalActualShippingCost"
  | "billableTotalBeaconCost"
  | "billableTotalEstimatedCost"
  | "billableTotalEstimatedShippingCost"
  | "billableTotalEstimatedTax"
>;

// Insures a number is returned
const n = (v: unknown) => (v ? +v : 0);

export const getExtendedCost = (e: OrderCosts) => {
  const shipping = n(
    +e.billableTotalActualShippingCost || e.billableTotalEstimatedShippingCost
  );
  const tax = n(e.billableTotalEstimatedTax);
  const cost = +e.billableTotalActualCost || +e.billableTotalEstimatedCost;

  return cost + shipping + tax + +e.billableTotalBeaconCost;
};

export const getVariantName = (variant: Variant) =>
  variant.selectedVariantOptions.map((option) => option.name).join(" / ");

export const displayOrderSetStatus = (
  status: OrderSetStatus,
  orderSet?: OrderSet
) => {
  if (orderSet?.isWorkComplete && status === "in-progress")
    return "Work Complete";

  const t: Record<OrderSetStatus, string> = {
    inactive: "Not Started",
    "in-progress": "In Progress",
    submitted: "Submitted",
    approved: "Approved",
    canceled: "Canceled",
    "partial-approval-error": "Partial Approval Error",
  };
  return t[status];
};

export const displayToInventoryAllocation = (order: Order) =>
  order.type === "to-inventory"
    ? order.subState
      ? order.subState.name
      : order.territory
      ? order.territoryName
      : "National"
    : null;
