/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Warning } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import PropTypes from "prop-types";

import BudgetStatusChip from "@features/budgets/BudgetStatusChip";

import {
  clearOrderSetReports,
  setTriggerCSVFalse,
} from "../../redux/slices/ordering/orderSetHistorySlice";
import {
  formatDate,
  formatMoneyCents,
  formatMoneyString,
} from "../../utility/utilityFunctions";

const headCells = [
  { id: "budgetStatus", label: "Budget Status" },
  { id: "user", label: "Ordered By" },
  { id: "territory", label: "Territory" },
  { id: "itemNumber", label: "Sequence #", sort: true },
  { id: "anaplanProgramsLabel", label: "Program" },
  { id: "brand", label: "Brand" },
  { id: "itemType", label: "Item Type", sort: true },
  {
    id: "itemDescription",
    label: "Item Desc.",
  },
  { id: "state", label: "State" },
  { id: "packSize", label: "Qty / Pack" },
  {
    id: "totalQty",
    label: "Total Items",
  },
  { label: "Est. Cost", id: "estCost" },
  { label: "Est. Total Costs", id: "totalEstCost" },
  { label: "Beacon Costs", id: "beaconCost" },
  {
    label: "Est. Freight",
    id: "totalEstFreight",
  },
  {
    label: "Est. Taxes",
    id: "totalEstTaxes",
  },
  {
    label: "Est. Extended Total",
    id: "totalExtendedCost",
  },
  {
    id: "orderDate",
    label: "Order Submitted",
  },
  { id: "status", label: "Status" },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (!headCell.sort) {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
              >
                {headCell.label}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OrderApprovalByItemTable = ({
  items,
  handleSort,
  isOrdersLoading,
  scrollRef,
  itemRef,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("orderDate");

  const currentUserRole = useSelector((state) => state.user.role);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const statusConverter = (status) => {
    if (status === "inactive") {
      return "Not Started";
    } else if (status === "in-progress") {
      return "In Progress";
    } else if (status === "submitted") {
      return "Order Submitted";
    } else {
      return status[0].toUpperCase() + status.slice(1);
    }
  };

  const handleRowClick = (id) => {
    if (!["read-only", "compliance"].includes(currentUserRole)) {
      navigate(`/orders/open/${id}#approval`);
    }
  };

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearOrderSetReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          style={{ minWidth: "1325px" }}
          ref={itemRef}
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {!isOrdersLoading && items.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={11}>
                  <Typography className={classes.headerText}>
                    {`You currently don't have any items on record that match this search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isOrdersLoading &&
              items.length > 0 &&
              items.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  className={
                    !["read-only", "compliance"].includes(currentUserRole)
                      ? row.isOverDue
                        ? classes.cancelRow
                        : classes.orderHistoryRow
                      : null
                  }
                  onClick={() => handleRowClick(row.orderSetId)}
                >
                  <TableCell
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    <BudgetStatusChip budgets={row.budgets} />
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    <div tw="flex gap-2 items-center">
                      {row.status === "partial-approval-error" && (
                        <Tooltip
                          placement="top-start"
                          title={"Partial Approval Error"}
                        >
                          <div tw="bg-red-50 text-red-800 rounded-lg p-1 items-center flex">
                            <Warning tw="text-base" color="inherit" />
                          </div>
                        </Tooltip>
                      )}
                      {row.isOverDue && (
                        <Tooltip
                          placement="top-start"
                          title="This order was not submitted on it's intended schedule, please notify your administrator if you are seeing this message."
                        >
                          <WarningIcon
                            fontSize="small"
                            color="inherit"
                            style={{ margin: "0 5px 0 0" }}
                          />
                        </Tooltip>
                      )}
                      {row.user}
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {row.territoryName}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {row.itemNumber}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {row.anaplanProgramsLabel}
                  </TableCell>
                  {row.brand.length > 1 ? (
                    <Tooltip placement="left" title={`${row.brand.join(", ")}`}>
                      <TableCell
                        align="left"
                        className={row.isOverDue ? classes.cancelCell : null}
                      >
                        {row.brand[0]}
                        <MoreHorizIcon fontSize="small" color="inherit" />
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell
                      align="left"
                      className={row.isOverDue ? classes.cancelCell : null}
                    >
                      {row.brand[0]}
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {row.itemType}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {row.itemDescription}
                  </TableCell>
                  {row.state !== "---" && row.state.length > 2 ? (
                    <Tooltip
                      title={`${row.state.split(", ").splice(1).join(", ")}`}
                    >
                      <TableCell
                        align="left"
                        className={row.isOverDue ? classes.cancelCell : null}
                      >
                        <Typography variant="body2">{`${
                          row.state.split(", ")[0]
                        }...`}</Typography>
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell
                      align="left"
                      className={row.isOverDue ? classes.cancelCell : null}
                    >
                      {row.state}
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {row.packSize}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {row.totalQty}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {formatMoneyCents(row.estCost)}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {formatMoneyCents(row.totalEstCost)}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {formatMoneyCents(row.totalBeaconCost * 100)}
                  </TableCell>
                  <TableCell>
                    {formatMoneyString(row.totalEstFreight)}
                  </TableCell>
                  <TableCell>{formatMoneyString(row.totalEstTaxes)}</TableCell>
                  <TableCell>
                    {formatMoneyString(row.totalExtendedCost)}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {row.orderDate !== "---"
                      ? format(formatDate(row.orderDate), "MM/dd/yyyy")
                      : row.orderDate}
                  </TableCell>

                  <TableCell
                    align="left"
                    className={row.isOverDue ? classes.cancelCell : null}
                  >
                    {statusConverter(row.status)}
                  </TableCell>
                </TableRow>
              ))}
            {isOrdersLoading && (
              <TableRow>
                <TableCell align="left" colSpan={11}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

OrderApprovalByItemTable.propTypes = {
  items: PropTypes.array,
  handleSort: PropTypes.func.isRequired,
  isOrdersLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any.isRequired,
};

export default OrderApprovalByItemTable;
