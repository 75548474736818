import _, { camelCase } from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../../../api/axiosCalls";
import { couponClonableFields } from "../../../components/PlanningTool/ItemPrograms/helpers";
import { kebabCaseKeys } from "../../../utility/utilityFunctions";
import { setError } from "../errorSlice";
import {
  handleVariantUpdates,
  mapImage,
  mapItemProgram,
  mapItemPrograms,
} from "./itemProgramsHelpers";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  orderType: null,
  packSize: null,
  entities: {},
  itemIdMap: {},
  newEntityDefaults: {},
  current: {},
  submitErrors: {},
};

const itemProgramSlice = createSlice({
  name: "itemPrograms",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getItemProgramsSuccess(state, { payload }) {
      const { items } = payload;
      const obj = items.reduce((a, b) => {
        a[b.id] = b;
        return a;
      }, {});
      state.entities = obj;
      state.isLoading = false;
    },
    updateItemProgramSuccess(state, action) {
      const { item } = action.payload;

      state.entities[item.id] = {
        ...(state.entities[item.id] || {
          openItemRow: 1,
        }), // open row if new item
        ...item,
      };
      state.isUpdateLoading = false;
    },
    deleteItemProgramSuccess(state, { payload }) {
      const { itemProgramId, clones } = payload;
      delete state.entities[itemProgramId];

      clones.forEach((cloneId) => {
        if (state.entities[cloneId]) {
          state.entities[cloneId].clonedFromId = null;
        }
      });
    },
    clearItemPrograms(state) {
      state.entities = {};
      state.isLoading = false;
    },

    setNewEntityDefaults(state, { payload: { type, data } }) {
      state.newEntityDefaults = { type, ...data };
    },

    clearNewEntityDefaults(state) {
      state.newEntityDefaults = {};
    },
    getSingleItemProgramSuccess(state, action) {
      const { item } = action.payload;
      state.current = item;
      state.isLoading = false;
    },
    clearCurrentItemProgram(state) {
      state.current = {};
    },
    setSubmitErrors(state, { payload }) {
      state.submitErrors = payload;
      Object.keys(payload).forEach((key) => {
        state.entities[key].openItemRow =
          state.entities[key].openItemRow + 1 || 1;
      });
    },
    clearSubmitErrors(state) {
      state.submitErrors = {};
    },
    expandItemProgram(state, { payload }) {
      state.entities[payload].openItemRow =
        state.entities[payload].openItemRow + 1 || 1;
    },
    updateClones(state, action) {
      const { clones, ...fields } = action.payload;
      const masterFields = _.pick(fields, couponClonableFields);
      clones.forEach((cloneId) => {
        if (state.entities[cloneId]) {
          state.entities[cloneId] = {
            ...state.entities[cloneId],
            ...masterFields,
          };
        }
      });
    },

    addImageSuccess(state, action) {
      const { itemProgramId, image } = action.payload;
      state.entities[itemProgramId].images.push(image);
    },

    removeImageSuccess(state, action) {
      const { itemProgramId, imageId } = action.payload;
      state.entities[itemProgramId].images = state.entities[
        itemProgramId
      ].images.filter((img) => img.id !== imageId);
    },
    updateSpecSuccess(state, action) {
      const { itemProgramId, specification } = action.payload;
      state.entities[itemProgramId].specification = specification;
    },
    updateSupplierSuccess(state, action) {
      const { itemProgramId, supplierId } = action.payload;
      state.entities[itemProgramId].supplierId = supplierId;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getItemProgramsSuccess,
  updateItemProgramSuccess,
  deleteItemProgramSuccess,
  clearItemPrograms,
  setNewEntityDefaults,
  clearNewEntityDefaults,
  getSingleItemProgramSuccess,
  clearCurrentItemProgram,
  setSubmitErrors,
  clearSubmitErrors,
  expandItemProgram,
  updateClones,
  addImageSuccess,
  removeImageSuccess,
  updateSpecSuccess,
  updateSupplierSuccess,
} = itemProgramSlice.actions;

export default itemProgramSlice.reducer;

/**
 * Some item actions specifically used by the planning-tool
 */

export const createItem = (programId, data, callback) => async (dispatch) => {
  const {
    orderCalendarMonthId,
    orderType,
    itemTypeId,
    moq,
    estimatedCost,
    estimatedQty,
    variantOptionsIds,
    complexityScore,
    brands,
    ...itemData
  } = data;
  try {
    const res = await axiosPost("/api/items", {
      data: {
        attributes: kebabCaseKeys({
          ...itemData,
          itemTypeId: +itemTypeId,
        }),
      },
    });
    if (res.error) throw res.error;

    await handleVariantUpdates({
      itemId: res.data.id,
      variants: [],
      variantOptionsIds,
    });

    dispatch(
      createItemProgram(
        {
          itemId: res.data.id,
          programId,
          orderCalendarMonthId,
          orderType,
          moq,
          estimatedCost,
          estimatedQty,
          complexityScore,
          brands,
        },
        callback
      )
    );
  } catch (error) {
    dispatch(setError({ error, source: "Create item (item program)" }));
  }
};

export const updateItem = async (
  itemId,
  { itemSubTypeId, ...attributes },
  data
) => {
  const body = {
    data: {
      ...kebabCaseKeys(data ?? {}),
      attributes: kebabCaseKeys(attributes),
      relationships: {
        ...(itemSubTypeId && {
          "item-sub-type": {
            data: { id: itemSubTypeId },
          },
        }),
      },
    },
  };
  const res = await axiosPut(`/api/items/${itemId}`, body, { timeout: 60000 });

  if (res.error) throw res.error;
  return res.data;
};

export const createItemProgram =
  (
    {
      itemId,
      programId,
      orderCalendarMonthId,
      orderType,
      moq,
      estimatedCost,
      estimatedQty,
      complexityScore,
      brands = [],
    },
    callback
  ) =>
  async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      const body = {
        data: {
          attributes: kebabCaseKeys({
            itemId: +itemId,
            programId,
            orderType,
            moq,
            estimatedCost,
            estimatedQty,
            complexityScore,
            ...(orderCalendarMonthId && { orderCalendarMonthId }),
          }),
          // @todo fix
          relationships: {
            brands: {
              data: brands.map((brand) => ({ id: brand.id, type: "brand" })),
            },
          },
        },
      };
      const res = await axiosPost("/api/item-programs", body);
      if (res.error) throw res.error;
      dispatch(updateItemProgramSuccess({ item: mapItemProgram(res.data) }));
    } catch (error) {
      dispatch(setError({ error, source: "Create/connect item program" }));
    } finally {
      callback && callback();
    }
  };

export const deleteItemProgram =
  (itemProgramId, clones) => async (dispatch) => {
    try {
      const res = await axiosDelete(`/api/item-programs/${itemProgramId}`);
      if (res.error) throw res.error;
      dispatch(
        deleteItemProgramSuccess({ itemProgramId, clones: clones || [] })
      );
    } catch (error) {
      dispatch(setError({ error, source: "Delete program items" }));
    }
  };

export const updateItemProgram =
  (itemProgram, formData, callback) => async (dispatch) => {
    const { itemId } = itemProgram;
    const {
      orderCalendarMonthId,
      moq,
      estimatedCost,
      orderType,
      complexityScore,
      beaconCost,
      brands,
      ...newItemData
    } = formData;
    try {
      await updateItem(itemId, newItemData);
      const res = await axiosPut(`/api/item-programs/${itemProgram.id}`, {
        data: {
          attributes: kebabCaseKeys({
            moq,
            estimatedCost,
            orderType,
            complexityScore,
            beaconCost,
          }),
          relationships: {
            ...(orderCalendarMonthId && {
              "order-calendar-month": {
                data: { id: orderCalendarMonthId },
              },
            }),
            ...(brands && {
              brands: {
                data: brands.map((brand) => ({ id: brand.id, type: "brand" })),
              },
            }),
          },
        },
      });
      if (res.error) throw res.error;
      const mappedItemData = mapItemProgram(res.data);

      dispatch(updateItemProgramSuccess({ item: mappedItemData }));
      dispatch(updateClones(mappedItemData));
    } catch (error) {
      console.error(error);
      dispatch(setError({ error, source: "Update item program" }));
    }

    callback && callback();
  };

export const fetchItemPrograms = (programId) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const res = await axiosGet(
      `/api/item-programs?skip_pagination=true&filter[program-id]=${programId}`
    );

    if (res.error) throw res.error;
    dispatch(getItemProgramsSuccess({ items: mapItemPrograms(res.data) }));
  } catch (error) {
    dispatch(setError({ error, source: "Fetch program items" }));
  }
};
export const fetchSingleItemProgram =
  ({ programId, itemId }, callback) =>
  async (dispatch) => {
    try {
      const res = await axiosGet(
        `/api/item-programs?filter[program-id]=${programId}&filter[item-id]=${itemId}`
      );

      if (res.error) throw res.error;

      dispatch(
        getSingleItemProgramSuccess({
          item: mapItemProgram(res.data[0]),
        })
      );

      callback && callback();
    } catch (error) {
      dispatch(setError({ error, source: "Fetch program items" }));
    }
  };
export const refreshItemProgram =
  (itemProgramId, callback) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      const res = await axiosGet(`/api/item-programs/${itemProgramId}`);

      if (res.error) throw res.error;

      const mappedItemData = mapItemProgram(res.data);
      dispatch(
        updateItemProgramSuccess({
          item: mappedItemData,
        })
      );

      dispatch(updateClones(mappedItemData));

      callback && callback(mappedItemData);
    } catch (error) {
      dispatch(setError({ error, source: "Fetch program items" }));
    }
  };

export const handleSubmitErrors = (errorsArr) => (dispatch) => {
  try {
    const errorObj = errorsArr.reduce((obj, err) => {
      const itemProgramId = err.meta.item_program_id;
      if (!obj[itemProgramId]) obj[itemProgramId] = [];
      obj[itemProgramId].push({
        name: camelCase(err.source),
        message: err.detail,
      });
      return obj;
    }, {});
    dispatch(setSubmitErrors(errorObj));
  } catch (error) {
    console.log(error);
  }
};

export const cloneCouponOffer =
  (itemProgramId, data, callback) => async (dispatch) => {
    let { moq, estimatedCost, complexityScore, ...itemData } = data || {};
    try {
      let res = await axiosPost(
        `/api/item-programs/${itemProgramId}/clone-offer`,
        {
          data: {
            attributes: kebabCaseKeys({
              ...itemData,
              supplierId: itemData.supplierId ? +itemData.supplierId : null,
            }),
          },
        }
      );
      if (res.error) throw res.error;

      res = await axiosPut(`/api/item-programs/${res.data.id}`, {
        data: {
          attributes: kebabCaseKeys({ moq, estimatedCost, complexityScore }),
        },
      });

      if (res.error) throw res.error;

      dispatch(
        updateItemProgramSuccess({
          item: mapItemProgram(res.data),
        })
      );
      callback && callback();
    } catch (error) {
      dispatch(setError({ error, source: "Clone coupon offer" }));
    }
  };

export const addImage =
  (itemProgramId, data, maybeCallback) => async (dispatch) => {
    try {
      const res = await axiosPost(`/api/images/`, {
        data: {
          attributes: kebabCaseKeys(data),
        },
      });

      if (res.error) throw res.error;

      dispatch(addImageSuccess({ itemProgramId, image: mapImage(res.data) }));

      maybeCallback?.();
    } catch (error) {
      dispatch(setError({ error, source: "Add image" }));
    }
  };
export const deleteImage =
  (itemProgramId, itemId, imageId, maybeCallback) => async (dispatch) => {
    try {
      const res = await axiosDelete(`/api/images/${imageId}`, {
        "item-id": itemId,
      });

      if (res.error) throw res.error;

      dispatch(removeImageSuccess({ itemProgramId, imageId }));

      maybeCallback?.();
    } catch (error) {
      dispatch(setError({ error, source: "Remove image" }));
    }
  };

export const updateSpecification =
  (itemProgramId, itemId, specification, maybeCallback) => async (dispatch) => {
    try {
      const item = await updateItem(itemId, {
        standardSpecificationCode:
          specification["Standard Spec Code"] !== ""
            ? specification["Standard Spec Code"]
            : null,
        specification,
      });

      dispatch(
        updateSpecSuccess({
          itemProgramId,
          specification: item.specification,
        })
      );

      maybeCallback?.();
    } catch (error) {
      console.error(error);
      dispatch(setError({ error, source: "Update Specs" }));
    }
  };
export const updateSupplier =
  (itemProgramId, itemId, supplierId, maybeCallback) => async (dispatch) => {
    try {
      const item = await updateItem(itemId, { supplierId });
      dispatch(
        updateSupplierSuccess({ itemProgramId, supplierId: item.supplier.id })
      );

      maybeCallback?.();
    } catch (error) {
      console.error(error);
      dispatch(setError({ error, source: "Update supplier" }));
    }
  };
