/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Paper } from "@mui/material";

import { SmartTable } from "src/components/SmartTable";
import { formatMoneyString } from "src/utility/utilityFunctions";

import {
  displayToInventoryAllocation,
  getExtendedCost,
} from "@features/orders";
import Tags from "@features/ui/Tags";

import { DisplayBillable } from "../BillableCosts";
import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";

const OrderSetOrdersOverview = () => {
  const { orders } = useCurrentOrderSet();

  return (
    <Paper>
      <SmartTable
        rows={orders ?? []}
        columns={[
          { id: "id", label: "Order #" },
          {
            id: "address.name",
            label: "Address",
            render: (value, order) =>
              order.type === "to-inventory" ? (
                <div tw="flex gap-2">
                  <Tags.Restock brandFunded={order.isBrandFunded} />
                  <span>{displayToInventoryAllocation(order)}</span>
                </div>
              ) : (
                value
              ),
          },
          {
            id: "address.abn",
            label: "ABN",
            render: (v) => v || "---",
          },
          {
            id: "_totalEstimatedCost",
            label: "Est. Cost",
            align: "right",
            render: (_v, order) => (
              <DisplayBillable
                billable={order.billableTotalEstimatedCost}
                estimate={order.totalEstimatedCost}
              />
            ),
          },
          {
            id: "_totalEstimatedShippingCost",
            label: "Est. Freight",
            align: "right",
            render: (_v, order) => (
              <DisplayBillable
                billable={order.billableTotalEstimatedShippingCost}
                estimate={order.totalEstimatedShippingCost}
              />
            ),
          },
          {
            id: "_totalEstimatedTax",
            label: "Est. Tax",
            align: "right",
            render: (_v, order) => (
              <DisplayBillable
                billable={order.billableTotalEstimatedTax}
                estimate={order.totalEstimatedTax}
              />
            ),
          },
          {
            id: "_extendedTotal",
            label: "Total",
            align: "right",
            render: (_v, o) => formatMoneyString(getExtendedCost(o)),
          },
        ]}
      />
    </Paper>
  );
};

export default OrderSetOrdersOverview;
